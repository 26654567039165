body {
    background-color: #F0F2F5;
}

.overflow-table {
    .ant-table-container {
        overflow-x: scroll;
    }

    .ant-table-cell {
        background: white;
    }
}


.view-league-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.layout-spacing {
    padding: 0 50px;
    margin-top: 64px;
}

.table-row-highlight>.ant-table-cell {
    background-color: #bae0ff;
}

.eliminated-player-row>.ant-table-cell {
    background-color: #efefef;
}

.focus-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    /* justify-content: center; */
    align-items: center;
    z-index: 1000;
    /* Adjust as needed */
}

.focused-item {
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7);
    z-index: 1001;
    /* Should be higher than focus-overlay */
}

.overlay-message {
    position: absolute;
    bottom: 50%;
    /* Adjust based on where your button is */
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background: #333;
    padding: 10px;
    border-radius: 5px;
    z-index: 1002;
    /* Should be higher than focused-item */
}


@media only screen and (max-width: 576px) {
    .hide-on-mobile {
        display: none;
    }

    .view-league-buttons {
        flex-direction: column;
        align-items: baseline;
        gap: 16px;
    }

    .layout-spacing {
        padding: 0 25px;
        margin-top: 0;
    }
}

@media only screen and (min-width: 577px) {
    .show-on-mobile {
        display: none;
    }
}

.main-card {
    text-align: center;
    padding: 16px;
    margin-bottom: 8px;
    background-color: #141414CC;
    border: none;
    max-width: 500px;
}

.main-card-text {
    color: #D1D1D1;
    font-family: Impact, sans-serif;
    font-size: 1.5rem;
}

.main-title {
    color: #ECD7B5;
    font-size: 3rem;
    font-family: Impact, sans-serif;
    font-weight: normal;
    line-height: 4rem;
}

.login-button {
    background-color: #04B7AA;
    border-color: #00C896;
    color: white;
}

.login-button:focus {
    background-color: #04B7AA;
    border-color: #00C896;
    color: white;
}

.login-button:hover {
    background-color: #D1D1D1;
    border-color: #00C896;
    color: #00C896;
}

.emblem-container {
    @media only screen and (min-width: 577px) {
        display: flex;
        gap: 16px;
    }
}

.emblem {
    text-align: center;
    padding: 16px;
    margin-bottom: 8px;
    background-color: #141414CC;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 500px;

    @media only screen and (min-width: 577px) {
        max-width: 200px;
    }
}

.clickable-emblem {
    cursor: pointer;
}

.emblem-title {
    color: #D1D1D1;
}

.emblem-text {
    color: #D1D1D1;
}

.emblem-link {
    color: #ECD7B5;
}

.custom-modal .ant-modal-content {
    background-color: #141414f1 !important;
    /* Dark semi-transparent background */
    color: #D1D1D1 !important;
    /* Light gray text */
    border-radius: 8px;
}

.custom-modal .ant-modal-title,
.custom-modal h1,
.custom-modal h2,
.custom-modal h3,
.custom-modal h4,
.custom-modal h5,
.custom-modal h6 {
    color: #D1D1D1 !important;
    /* Match text color */
    font-weight: bold;
}

.custom-modal .ant-modal-header {
    background-color: #141414f1 !important;
    border-bottom: 1px solid #2D2D2D;
    /* Subtle border */
}

.custom-modal .ant-modal-footer {
    background-color: #1E1E1ECC !important;
    border-top: 1px solid #2D2D2D;
    /* Subtle border */
}

.custom-modal .ant-modal-close-x {
    color: #FFFFFF !important;
    /* White close button */
}

.custom-modal .ant-btn-primary {
    background-color: #00C896 !important;
    border-color: #00A884 !important;
    color: #FFFFFF !important;
}

.custom-modal .ant-btn-primary:hover {
    background-color: #009977 !important;
    border-color: #008866 !important;
}